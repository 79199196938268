body {
  //color: #FFF;
  text-transform: none !important;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

h1,
h2,
//.btn,
legend,
.modal-title,
#footer h5,
.loading-spinner-content {
  //text-transform: uppercase;
  //letter-spacing: 1px;
}

body.product-listing-page,
body.product-detail-page,
body.ticket-listing-page,
body.ticket-detail-page {
  #main {
    background: url('./assets/images/bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    // background: linear-gradient(
    //   180deg,
    //   #ffed04 0%,
    //   #b08e88 15%,
    //   #8b9fb6 25%,
    //   #ebbc8e 75%,
    //   #f9c186 80%,
    //   #e9c497 85%,
    //   #a7c7cb 100%
    // );
    // background-color: $jorisblue;
    //background: radial-gradient(ellipse at top, lighten($jorisblue, 0%), lighten($jorisblue, 5%)),
    //  radial-gradient(ellipse at bottom, lighten($jorisblue, 5%), lighten($jorisblue, 0%));
  }
  .product-content {
    background: transparent;
  }
}

.ticket-listing-page .navbar-brand,
.product-listing-page .navbar-brand {
  // opacity: 0;
  // transform: translateY(-10px);
  // transition: all 0.1s;
  // transition-delay: 0;
}

#main {
  // background: linear-gradient(
  //   180deg,
  //   #ffed04 0%,
  //   #b08e88 15%,
  //   #8b9fb6 25%,
  //   #ebbc8e 75%,
  //   #f9c186 80%,
  //   #e9c497 85%,
  //   #a7c7cb 100%
  // );

  //background-color: $white;
  //z-index: -2; //fixes OL bug on checkout
}

#header {
  transition: all 0.3s ease-in-out;
  height: $calculated-navbar-height;
  box-shadow: $box-shadow;
  min-height: 121px;
  @include media-breakpoint-down(sm) {
    max-height: $calculated-navbar-height-sm;
    min-height: 80px;
  }
  background: $black;
  // background-size: contain;
  // background-repeat: no-repeat;
  // background: linear-gradient(-30deg, #ff00ff 0%, #00ffff 60%, #baed27 120%);
  // background: linear-gradient(180deg, #020304 0%, #008384 33%, #ed6c07 66%, #add19d 83%, #ecb7cc 100%);
}

body[data-scrolled] {
  #header {
  }
}

#footer {
  box-shadow: $box-shadow-inverse;
  color: $white;
  background: $black;
  // background: linear-gradient(-30deg, #ff00ff 10%, #00ffff 90%, #baed27 110%);
  // background: linear-gradient(180deg, #f3afce 5%, #fdd5ab 10%, #eb6f10 33%, #49c0ec 90%, #ffed04 100%);
  // background: linear-gradient(180deg, #49C0EC 80%, #FFED04 100%);
  color: $white;
  padding-top: 30px;

  hr {
    height: 3px;
    background: $white;
    width: clamp(600px, 80vw, 1300px);
    margin-top: 0;
    margin-bottom: 60px;
  }
  a {
    color: $white;
  }
  .copyright-row {
    border-top: 3px solid $white;
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
      filter: invert(1);
    }
    margin-top: 25px;
  }
}

// MENU & NAVBAR

#main-menu {
  background: transparent;
  .nav-item {
    margin-right: 0 !important;
  }
  .nav-link {
    background-color: $white;
    color: $black;
    font-weight: 600;
    margin-bottom: 0.4rem;
    //font-weight: 900;
    //text-transform: uppercase;
    @include media-breakpoint-down(md) {
      //color: white !important;
    }
  }
}

#header > .navbar {
  height: $calculated-navbar-height;
  min-height: 121px;
  @include media-breakpoint-down(sm) {
    max-height: $calculated-navbar-height-sm;
    min-height: 80px;
  }
  //font-size: 16px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  @include media-breakpoint-up(md) {
    //font-size: 20px;
    font-size: $h5-font-size;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-brand-container {
    position: absolute;
    top: 0rem;
    left: 3vw;
    @include media-breakpoint-down(sm) {
      left: 2vw;
    }
  }

  .navbar-brand {
    // background-image: url('./assets/images/logo.png');
    // background-position: center;
    // background-repeat: no-repeat;
    padding: 0;
    transition: all 0.3s ease-in-out;
    img {
      //display: none;
      max-height: $calculated-navbar-height * 2.5;
      @include media-breakpoint-down(xl) {
        max-height: $calculated-navbar-height-sm * 1.9;
      }
    }
    // @include media-breakpoint-down(md) {
    //   background-size: 6rem;
    //   height: 6rem;
    //   width: 6rem;
    // }
    // background-size: 7rem;
    // height: 7rem;
    // width: 7rem;
    // transition: all 0.15s ease-in-out;
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.navbar .hamburger {
  padding: 0.5rem 1rem;
  @include media-breakpoint-down(sm) {
    padding-right: 0.1rem;
  }
}

.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $white !important;
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  //height: 1px;
  color: $white;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar ul.menu {
  @extend .mr-4;
}

.navbar {
  .fa,
  .fas {
    color: $black;
    font-size: $h5-font-size;
  }
}

.navbar ul.menu .nav-link {
  background-color: $white;
  padding: 0.71rem;
  font-weight: 600;
  &:hover {
    text-decoration: underline solid currentColor;
    text-underline-offset: 6px;
  }
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent !important;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}
.offcanvas-collapse-right.navbar-collapse {
  background-color: transparent !important;
}

.offcanvas-collapse-right.offcanvas-collapse-lg {
  background-color: rgba($white, 0.7) !important;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $white, $alpha: 0.97) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    //text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  &.card {
    background-color: $white;
  }

  @extend .p-3;
  .card-title {
    text-align: left;
    .title {
      //text-transform: uppercase;
      font-size: $h5-font-size;
      @extend .text-truncate;
    }

    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
  }

  .card-body {
    flex-direction: column;
    padding-bottom: 0;
  }

  transition: all 0.15s ease-in-out;
  box-shadow: $box-shadow;

  &:hover {
    box-shadow: $box-shadow-xl;
    transition: all 0.15s ease-in-out;
    //transform: rotate(-2deg);
  }
  .maki-img-hover {
    display: none !important;
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  //border-bottom: 1px solid $gray-300;
  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    //color: $white;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h3-font-size !important;
    font-family: 'MTFont_bold', sans-serif;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

// PDP

body.ticket-detail-page {
  color: $white;

  .detail-page-inner a {
    color: $white;
  }

  .card {
    border: 0;
  }
  .card.article-title {
    h1 {
      //color: $jorisred;
      color: $white;
      font-size: $h1-font-size;
      small {
        color: $gray-600;
      }
    }
  }
  .product-info-bar {
    //i {color: $agorange !important;}
    box-shadow: $box-shadow;
    background: transparent !important;

    .btn {
      color: $white;
    }

    button {
      strong {
        color: $white;
      }

      &:hover {
        color: $white;
      }
    }
  }
  .collapse {
    background-color: rgba($white, 0.75);
  }
}

.detail-page {
  h1 {
    margin-top: 0;
    // color: $white !important;
    //text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent !important;
}

.blurry-background-image:before {
  display: none;
  //   background: linear-gradient(
  //     180deg,
  //     #ffed04 0%,
  //     #b08e88 15%,
  //     #8b9fb6 25%,
  //     #ebbc8e 75%,
  //     #f9c186 80%,
  //     #e9c497 85%,
  //     #a7c7cb 100%
  //   );
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  //box-shadow: $box-shadow-xl;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  //backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    background-color: transparent !important;
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      background-color: $white !important;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

.DayPicker-Day--disabled {
  color: $gray-600 !important;
}

.DayPicker .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
  background-color: $success;
  color: $white !important;
}

// safari fix from-to dates on nigiri
.nigiri-body {
  display: flex;
  align-items: center;
}

@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

.cart-listing {
  background-color: $white;
}

.modal-content {
  color: black;
}
